import React, { useState, useEffect } from "react";
import _ from "lodash";
import { ArrowUpIcon } from "@heroicons/react/24/outline";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Add a scroll event listener to show/hide the button
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`fixed right-4 bottom-4 p-2 rounded-full bg-green-600 text-white ${
        isVisible ? "block" : "hidden"
      }`}
      onClick={scrollToTop}
    >
      <div className="flex flex-shrink-0 items-center justify-center h-8 w-8 text-white">
        <ArrowUpIcon className="h-4 w-4 font-bold" />
      </div>
    </button>
  );
};

export default ScrollToTopButton;
