import _ from "lodash";
import React, { useCallback, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import { capture, useBrightFeatureFlag } from "../common/analytics";
import { Layout, SEO, QuoteForm, Button } from "../components";
import { useSeoProps } from "../components/useSeoProps";
import ScrollToTopButton from "../components/ScrollToTopButton";
import BrightJourneySteps from "../components/BrightJourneySteps";

const FormContent = () => {
  const variant = useBrightFeatureFlag("interactive_proposal_7");
  let nextSteps = <BrightJourneySteps />;

  return (
    <div className="w-full mx-auto max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
      <div className="flex w-full flex-col md:flex-row mx-auto max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="w-full md:w-1/2 py-4 px-6">
          <QuoteForm
            form="gatsby_cotizar"
            interactiveProposalVariant={variant}
            exactBill={true}
          />
        </div>
        <div className="w-full md:w-1/2 flex py-8  lg:px-8 md:px-0 items-start justify-center ">
          {nextSteps}
          <ScrollToTopButton />
        </div>
      </div>
    </div>
  );
};

const SplashScreen = ({ onStart }) => {
  let detailText =
    "Obtén una propuesta personalizada respondiendo unas preguntas";
  let buttonText = "Empezar";

  return (
    <div className="flex justify-center items-start h-screen pt-6">
      <div className="absolute md:pt-0 pt-72 md:bottom-0 -z-10">
        <StaticImage src="../images/panels-bg.png" alt="Solar Panels" />
      </div>
      <div className="flex relative flex-col text-center gap-2 items-center w-full md:w-1/2 text-blue-500 bg-white/50 backdrop-blur-sm px-5">
        <div className="text-3xl md:text-6xl uppercase font-extrabold">
          Toma el control de tus pagos de luz sin sacrificar tu confort
        </div>
        <div className="text-3xl md:text-6xl font-extrabold italic text-green-700">
          Con Energía Solar <br></br>SIN INVERSIÓN
        </div>
        <div className="text-lg font-bold text-gray-600">{detailText}</div>
        <div className="m-4">
          <Button onClick={onStart}>
            <span>{buttonText}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

const InteractiveProposalPage = () => {
  const [showSplash, setShowSplash] = useState(true);

  const metaTitle = "Solicita tu propuesta 100% personalizada sin costo";
  const metaDescription =
    "Solicita una cotización totalmente gratis, ¿Estás listo para hacer el cambio a energía solar?";

  const seoProps = useSeoProps(
    "propuesta-interactiva",
    metaTitle,
    metaDescription,
  );

  const handleStartPressed = useCallback(() => {
    capture("interactive_proposal_started");
    setShowSplash(false);
  }, [setShowSplash]);

  let componentToShow: React.ReactElement;
  if (showSplash) {
    componentToShow = <SplashScreen onStart={handleStartPressed} />;
  } else {
    componentToShow = <FormContent />;
  }

  return (
    <Layout hideNav={true} hideFooter={true}>
      <SEO {...seoProps} />
      {componentToShow}
    </Layout>
  );
};

export default InteractiveProposalPage;
